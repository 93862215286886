const getCurrentRoute = (path) => {
  if (path === 'undefined') {
    return undefined;
  }

  const route = path.replace(/\/$/, '').split('/');
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  const currentPath = route[route.length - 1];
  if (regexExp.test(currentPath)) {
    return route[route.length - 2];
  }
  return currentPath;
};
export default getCurrentRoute;

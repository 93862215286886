import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { navigate as gatsbyNavigate } from 'gatsby';
import { Box, Grid, Typography } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TicketCard from './TicketCard';

const EventPopup = ({ eventInfo, start, end }) => {
  React.useEffect(() => {}, []);

  const goToTicketDetail = (e) => {
    if (e.id) gatsbyNavigate(`/events/detail/?event_id=${e.id}`);
  };

  const Popup = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 320,
      padding: 0,
      backgroundColor: 'unset !important',
    },
  });

  return (
    <Popup
      interactive
      placement="right"
      arrow
      leaveDelay={100}
      title={
        <Grid item xs={12}>
          <TicketCard eventInfo={eventInfo} />
        </Grid>
      }
    >
      <Box
        onClick={(event) => {
          event.stopPropagation();
          goToTicketDetail(eventInfo);
        }}
      >
        <Typography align="center" variant="body2">
          {`${moment(start).format('h:mm a')} - ${moment(end).format(
            'h:mm a'
          )}`}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          style={{ backgroundColor: '#CFCFCF', whiteSpace: 'normal' }}
        >
          {eventInfo?.title}
        </Typography>
      </Box>
    </Popup>
  );
};

EventPopup.propTypes = {
  eventInfo: PropTypes.shape({
    id: '',
    imageUrl: '',
    title: '',
    tagLine: '',
    startTime: '',
  }).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
};

export default EventPopup;

import React from 'react';
import { Router } from '@reach/router';
import TicketEventList from '../components/routes/Ticket/TicketEventList';

const CardPage = () => {
  return (
    <Router basepath="concert-series">
      <TicketEventList path=":businessId" />
      <TicketEventList path=":businessId/calendar" />
    </Router>
  );
};

export default CardPage;

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { navigate as gatsbyNavigate } from 'gatsby';

const TicketCard = ({ eventInfo }) => {
  const { t } = useTranslation();
  const goToTicketCheckout = () =>
    gatsbyNavigate(`/tickets/events/${eventInfo.id}`);
  const goToTicketDetail = () =>
    gatsbyNavigate(`/events/detail/?event_id=${eventInfo.id}`);
  return (
    <Card
      raised
      sx={{ borderRadius: '10px', border: '1px solid rgba(33, 37, 41, .3)' }}
    >
      <CardActionArea
        sx={{ maxHeight: 545, height: 500, padding: '20px', minWidth: 300 }}
        onClick={goToTicketDetail}
      >
        <CardMedia
          component="img"
          height="190"
          width="100%"
          image={eventInfo.imageUrl}
          alt={eventInfo.title}
          sx={{ marginBottom: 1 }}
        />
        <CardContent
          sx={{
            maxHeight: 175,
            height: 175,
            padding: 0,
            marginBottom: 2,
          }}
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            fontWeight="bold"
            sx={{ fontFamily: 'narcost-regular', fontSize: '1.2rem' }}
          >
            {eventInfo.title}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            maxHeight={50}
            overflow="hidden"
            sx={{ fontFamily: 'gilmer-bold', fontSize: '1rem' }}
          >
            {eventInfo.tagLine}
          </Typography>
        </CardContent>
        <Box sx={{ textAlign: 'left' }}>
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="left"
            pb={1.5}
            sx={{ fontFamily: 'gilmer', fontSize: '1rem' }}
          >
            {moment(`${eventInfo.startTime}Z`).format(
              'ddd, MMM D, YYYY h:mm A'
            )}
          </Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              goToTicketCheckout();
            }}
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 20,
              minWidth: '150px',
              height: '40px',
            }}
          >
            {t('ticket.buyTicket')}
          </Button>
        </Box>
      </CardActionArea>
    </Card>
  );
};
TicketCard.propTypes = {
  eventInfo: PropTypes.shape({
    id: '',
    imageUrl: '',
    title: '',
    tagLine: '',
    startTime: '',
  }).isRequired,
};

export default TicketCard;

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { navigate as gatsbyNavigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Divider,
  Box,
} from '@material-ui/core';
import EventPopup from './EventPopup';

const useStyles = makeStyles(() => ({
  event: {
    cursor: 'pointer',
    width: 'fit-content',
    margin: 'auto !important',
    '&:hover': {
      background: 'unset !important',
    },
    '&:focus': {
      boxShadow: 'unset !important',
      background: 'unset !important',
    },
    '&:focus::after': {
      boxShadow: 'unset !important',
      background: 'unset !important',
    },
  },
  selectedDate: {
    backgroundColor: '#C9F5FB !important',
  },
}));

const EventsCalendar = ({ eventList }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const isMDScreen = useMediaQuery(theme.breakpoints.down(930));

  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1)
      color += letters[Math.floor(Math.random() * 16)];
    return color;
  };

  const splitByMidnight = (event) => {
    try {
      const result = [];
      let currentStart = moment(`${event.startTime}Z`);
      const finalEnd = moment(`${event.endTime}Z`);
      const customColor = generateRandomColor();

      while (currentStart.isBefore(finalEnd)) {
        let currentEnd = moment(currentStart).endOf('day');
        if (currentEnd.isAfter(finalEnd)) {
          currentEnd = finalEnd;
        }
        result.push({
          id: event.id,
          title: event.title,
          start: currentStart.toISOString(),
          end: currentEnd.toISOString(),
          customColor,
        });
        currentStart = currentEnd.add(1, 'day').startOf('day');
      }

      return result;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (Array.isArray(eventList)) {
      let tempEvents = [];
      eventList.forEach((e) => {
        tempEvents = tempEvents.concat(splitByMidnight(e));
      });
      setEvents(
        tempEvents.map((e) => {
          return {
            id: `${e.id} - ${e.start}`,
            title: e.title,
            start: e.start,
            end: e.end,
            customColor: e.customColor,
            originalId: e.id,
          };
        })
      );
    } else {
      setEvents([]);
    }
  }, [eventList]);

  const eventContent = (info) => {
    const isDayGrid =
      info.view.type.includes('dayGridMonth') ||
      info.view.type.includes('dayGridWeek');

    if (isDayGrid && isMDScreen) {
      return (
        <Box
          className="mobile-event-dot"
          sx={{
            backgroundColor: info.event.extendedProps.customColor,
            width: 10,
            height: 10,
            borderRadius: '50%',
            display: 'inline-block',
            margin: 2,
          }}
        />
      );
    }

    return (
      <>
        <EventPopup
          eventInfo={eventList.find(
            (e) => e.id === info.event.extendedProps.originalId
          )}
          start={info.event.start}
          end={info.event.end}
        />
      </>
    );
  };

  const [selectedDate, setSelectedDate] = useState('');

  const handleDateClick = (info) => {
    const { dateStr } = info;
    setSelectedDate(dateStr);
  };

  const dateClassNames = (date) => {
    const dateStr = moment(date).format('YYYY-MM-DD');
    return selectedDate === dateStr ? classes.selectedDate : '';
  };

  const goToTicketDetail = (eventInfo) =>
    gatsbyNavigate(`/events/detail/?event_id=${eventInfo.id}`);

  return (
    <>
      <FullCalendar
        height="auto"
        events={events}
        initialView="dayGridMonth"
        eventClassNames={classes.event}
        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
        displayEventTime={false}
        displayEventEnd={false}
        eventOrder="start"
        eventContent={eventContent}
        eventBackgroundColor="#FFF"
        eventBorderColor="#FFF"
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        eventTextColor="#000000DE"
        contentHeight="auto"
        eventClick={(info) => {
          if (!isMDScreen) {
            goToTicketDetail(info.event.extendedProps.originalId);
            return;
          }
          const { start } = info.event;
          setSelectedDate(moment(start).format('YYYY-MM-DD'));
        }}
        dateClick={(info) => {
          handleDateClick(info);
        }}
        dayCellClassNames={(date) => dateClassNames(date.date)}
      />

      {isMDScreen &&
        selectedDate &&
        eventList
          .filter(
            (e) =>
              moment(`${e.startTime}Z`).isSameOrBefore(
                moment(selectedDate),
                'day'
              ) &&
              moment(`${e.endTime}Z`).isSameOrAfter(moment(selectedDate), 'day')
          )
          .map((e) => (
            <Grid
              item
              container
              spacing={2}
              style={{ marginTop: 24 }}
              key={e.id}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ padding: '0 20px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  goToTicketDetail(e);
                }}
              >
                <Typography>{`${moment(`${e.startTime}Z`).format(
                  'ddd, MMM D, YYYY h:mm A'
                )} - ${moment(`${e.endTime}Z`).format(
                  'ddd, MMM D, YYYY h:mm A'
                )}`}</Typography>
                <Typography
                  style={{ cursor: 'pointer', backgroundColor: '#DBDBDB' }}
                >
                  {e.title}
                </Typography>
                <Divider style={{ width: '100%', marginTop: 16 }} />
              </Grid>
            </Grid>
          ))}
    </>
  );
};

EventsCalendar.propTypes = {
  eventList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default EventsCalendar;
